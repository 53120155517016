<template>
  <div class="card-header-padding">
    <v-simple-table class="bborder">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              style="border-right: 1px solid #bbb; border-left: 1px solid #bbb"
            >
              Нэр
            </th>
            <th
              style="width: 200px"
              v-for="(day, dindex) in days"
              :key="'calendar-' + day.dayIndex + dindex"
              class="text-center font-weight-bold"
            >
              {{ day.name }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(xeelj, lindex) in xeeljs" :key="xeelj + lindex + 'eelj'">
            <td
              style="
                border-right: 1px solid #bbb;
                border-left: 1px solid #bbb;
                width: 10%;
              "
            >
              {{ xeelj }} -р цаг
            </td>
            <td
              @click.stop="_addNewCalData(day, xeelj)"
              v-for="(day, dindex) in days"
              :key="'cell-' + day.dayIndex + xeelj.index + dindex"
              :title="''"
              style="cursor: pointer"
              class="px-1 mx-1 py-4"
            >
              <v-row>
                <v-col cols="10">
                  <v-row>
                    <v-col
                      class="pr-0 pb-1 pt-0"
                      :cols="12 / _getCellLessons(day, xeelj).length"
                      v-for="(lesson, index) in _getCellLessons(day, xeelj)"
                      :key="'cell' + day + xeelj + index"
                    >
                      <v-chip
                        @click.stop="_clickedCell(day, xeelj, index)"
                        class="px-2"
                        style="
                          width: 100%;
                          height: 100%;
                          font-size: 10pt;
                          border-radius: 4px;
                          cursor: pointer;
                          display: block;
                        "
                        :style="_getLesson2(day, xeelj, index).style"
                        v-if="_getLesson2(day, xeelj, index)"
                        color="#00BCD4"
                        dark
                      >
                        <p class="mb-0">
                          {{ _getLesson2(day, xeelj, index).name }}
                        </p>
                        <small style="display: block">
                          <span
                            v-if="
                              _getLesson2(day, xeelj, index).teachers != null
                            "
                          >
                            {{ _getLesson2(day, xeelj, index).teachers }}
                          </span>
                          <span v-else>
                            !!!!
                            <v-icon>mdi-alert-decagram</v-icon></span
                          >
                        </small>
                        <strong style="display: block">
                          <span
                            v-if="
                              _getLesson2(day, xeelj, index).lessonTypeName !=
                              null
                            "
                          >
                            [{{
                              _getLesson2(day, xeelj, index).lessonTypeName
                            }}]
                          </span>
                        </strong>
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="!readable"
                  class="text-center"
                  cols="2"
                  @click.stop="_addNewCalData(day, xeelj)"
                >
                  <v-icon class="addIcon"> mdi-plus </v-icon>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog
      v-model="selectLessonDialog"
      max-width="50%"
      persistent
      v-if="selectedDay && selectedXeelj"
    >
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title
          @click="_print"
          class="pt-0 text-h5 text-typo justify-center font-weight-bold"
          ><span>Ээлжит xичээлээ сонгоно уу! </span>
        </v-card-title>
        <v-card-text class="text-h5 mt-4" v-if="selectedDay">
          <v-row>
            <v-col lg="6" md="6" sm="12">
              <span class="red--text">{{ selectedDay.name }}</span> гараг,
              <span class="red--text"> {{ selectedXeelj }}</span
              >-р цаг
              {{ calendarEeljIndex }}
            </v-col>
            <v-col class="text-end" v-if="calendarEeljIndex">
              <v-btn elevation="0" class="mb-0" color="#DCEDC8">
                {{ calendarEeljIndex }}-р ээлж
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-select
            :disabled="onlyAllowedLesson != null"
            v-if="lessons"
            return-object
            v-model="selectedLesson"
            :items="lessons"
            item-text="name2"
            item-value="ref"
            label="Xичээл сонгоx"
          >
            <template #item="{ item }">
              <v-row>
                <v-col cols="8">
                  <span class="text-typo">{{ item.name2 }}</span>
                </v-col>
                <v-col class="text-end">
                  <span class="red--text" v-if="item.selectedGroupName">{{
                    item.selectedGroupName
                  }}</span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:selection="{ item }">
              {{ item.name2 }}
              <span class="mx-4" v-if="item.selectedGroupName">
                -- {{ item.selectedGroupName }}</span
              >
            </template>
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="
              clearrr();
              selectedXeelj = null;
              selectedDay = null;
              selectLessonDialog = !selectLessonDialog;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>

          <template>
            <div v-if="selectedLesson && !selectedLesson.already">
              <v-spacer></v-spacer>
              <v-btn
                @click="_saveCalData"
                height="43"
                class="btn-primary bg-gradient-primary text-capitalize"
                >Xадгалаx</v-btn
              >
            </div>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
export default {
  data() {
    return {
      onlyForMe: false,
      selectedDate: null,

      selectedStartDate: null,
      showDateSelectDialog: false,
      selectedSemester: null,
      calendarEelj: null,

      calendarVersionNumber: null,
      deleteEeljIngredients: false,
      currentSelectedSemester: null,
      selectedCalendarIndex: 0,
      selectedCalendar: null,
      showEditCalendarDialog: false,
      school: null,

      xeeljs: [1, 2, 3, 4, 5, 6, 7, 8],
      calendarColors: [
        {
          subjectId: 1,
          fgColor: "#A5D6A7",
          bgColor: "black",
        },
        {
          subjectId: 2,
          fgColor: "#42A5F5",
          bgColor: "white",
        },
        {
          subjectId: 3,
          fgColor: "#90CAF9",
          bgColor: "black",
        },
        {
          subjectId: 4,
          fgColor: "#BBDEFB",
          bgColor: "black",
        },
        {
          subjectId: 5,
          fgColor: "#F48FB1",
          bgColor: "black",
        },
        {
          subjectId: 6,
          fgColor: "#C8E6C9",
          bgColor: "black",
        },
        {
          subjectId: 7,
          fgColor: "#A5D6A7",
          bgColor: "black",
        },
        {
          subjectId: 8,
          fgColor: "#81C784",
          bgColor: "black",
        },
        {
          subjectId: 9,
          fgColor: "#9575CD",
          bgColor: "white",
        },
        {
          subjectId: 10,
          fgColor: "#2962FF",
          bgColor: "white",
        },

        {
          subjectId: 11,
          fgColor: "#66BB6A",
          bgColor: "white",
        },

        {
          subjectId: 12,
          fgColor: "#FFF176",
          bgColor: "black",
        },
        {
          subjectId: 13,
          fgColor: "#FFD54F",
          bgColor: "black",
        },
        {
          subjectId: 14,
          fgColor: "#FFCA28",
          bgColor: "black",
        },
        {
          subjectId: 15,
          fgColor: "#FFAB91",
          bgColor: "black",
        },
        {
          subjectId: 16,
          fgColor: "#FF8A65",
          bgColor: "black",
        },
        {
          subjectId: 17,
          fgColor: "#FF7043",
          bgColor: "white",
        },
        {
          subjectId: 18,
          fgColor: "#B0BEC5",
          bgColor: "black",
        },
        {
          subjectId: 19,
          fgColor: "#F4511E",
          bgColor: "white",
        },
        {
          subjectId: 20,
          fgColor: "#E040FB",
          bgColor: "white",
        },
        {
          subjectId: 21,
          fgColor: "#40C4FF",
          bgColor: "black",
        },
        {
          subjectId: 22,
          fgColor: "#00E676",
          bgColor: "black",
        },
        {
          subjectId: 23,
          fgColor: "#DCE775",
          bgColor: "black",
        },
        {
          subjectId: 24,
          fgColor: "#FF5252",
          bgColor: "white",
        },
        {
          subjectId: 25,
          fgColor: "#FFE082",
          bgColor: "black",
        },
        {
          subjectId: 26,
          fgColor: "#B2DFDB",
          bgColor: "black",
        },
        {
          subjectId: 27,
          fgColor: "#CFD8DC",
          bgColor: "black",
        },
        {
          subjectId: 28,
          fgColor: "#CFD8DC",
          bgColor: "black",
        },

        {
          subjectId: 195,
          fgColor: "#FFE082",
          bgColor: "black",
        },
        {
          subjectId: 196,
          fgColor: "#26C6DA",
          bgColor: "white",
        },
        {
          subjectId: 179,
          fgColor: "#EEFF41",
          bgColor: "black",
        },
        {
          subjectId: 233,
          fgColor: "#26A69A",
          bgColor: "white",
        },
        {
          subjectId: 245,
          fgColor: "#FBC02D",
          bgColor: "black",
        },
      ],
      selectedClassGroup: null,
      selectedLesson: null,
      selectLessonDialog: false,
      deleteConfirmDialog: false,
      days: [
        { name: "Даваа", dayIndex: 1 },
        { name: "Мягмар", dayIndex: 2 },
        { name: "Лхагва", dayIndex: 3 },
        { name: "Пүрэв", dayIndex: 4 },
        { name: "Баасан", dayIndex: 5 },
      ],
      availableTimes: null,
      selectedXeelj: null,
      selectedDay: null,
      xeelj: null,
    };
  },
  props: {
    lessons: {
      type: Array,
      required: true,
    },
    calendarData: {
      type: Array,
    },
    onlyAllowedLesson: {
      type: Object,
    },
    calendarEeljIndex: null,
    defaultCalendarVersion: {
      type: Object,
    },
    readable: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.onlyAllowedLesson) this.selectedLesson = this.onlyAllowedLesson;
  },
  watch: {
  },
  methods: {
    _print() {},
    async _saveCalData() {
      if (this.selectedLesson) {
        if (
          this.selectedLesson.courseInfo.COURSE_NAME.includes("Бэлтгэл хичээл")
        ) {
          this.$swal.fire(
            "Бэлтгэл xичээлийн олон цагийн xуваарь багшийн журнал дээр автоматаар тавигдаx тул ТА бусад xичээлийн xуваариа оруулаарай."
          );
        } else {
          console.log(
            this.selectedLesson.ref.path,
            this.selectedXeelj,
            this.selectedDay,
            this.defaultCalendarVersion
          );
          //ttt
          var calString =
            this.selectedSemester +
            1 +
            "-" +
            this.defaultCalendarVersion.calendarVersionNumber +
            "-" +
            this.selectedDay.dayIndex +
            "-" +
            this.calendarEeljIndex +
            "-0" +
            this.selectedXeelj;

          console.log(calString);
          this.selectedLesson.ref
            .update({
              _calDatas: fb.firestore.FieldValue.arrayUnion(calString),
            })
            .then(() => {
              this.selectedXeelj = null;
              this.selectedDay = null;
              this.selectLessonDialog = !this.selectLessonDialog;
            });
        }
      } else {
        this.$swal.fire("Xичээлээ сонгоно уу!");
      }
    },
    _printCal() {
      for (const cal of this.calendarData) {
        console.log(cal);
      }
    },

    clearrr() {
      if (!this.onlyAllowedLesson) this.selectedLesson = null;
    },

    _getCellColor(lesson) {
      var x = this.calendarColors.find(
        (item) => item.subjectId == lesson.courseInfo.SUBJECT_AREA_ID
      );
      if (
        this.onlyAllowedLesson &&
        this.onlyAllowedLesson.courseInfo.COURSE_ID !=
          lesson.courseInfo.COURSE_ID
      ) {
        x = {
          fgColor: "#b3b3b3",
          bgColor: "black",
        };
      }
      if (x) return x;
      else {
        return {
          subjectId: -1,
          fgColor: "#bbb",
          bgColor: "black",
        };
      }
    },
    _getTeacherNames(calData) {
      var found = this.lessons.find((ll) => ll.id == calData.lesson.ref.id);
      if (found)
        return found.byTeachers
          .filter((tt) => tt.xSemester == this.selectedSemester + 1)
          .map((tt) => tt.teacherDisplayName)
          .join(", ");
      else return null;
    },

    async _clickedCell(day, xeelj, index) {
      if (!this.readable) {
        this.selectedDay = day;
        this.selectedXeelj = xeelj;
        var clickedCalData = null;
        clickedCalData = this._getCellLessons(
          day,
          xeelj,
          this.calendarEeljIndex
        )[index];
        console.log(clickedCalData, "clickedCalData");
        this.$swal({
          title: "Xуваарийг устгаx уу?",
          text: "Шинээр системд xадгална.",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            console.log("clickedCalData", clickedCalData);
            var calString =
              clickedCalData.semester +
              "-" +
              clickedCalData.calendarVersion +
              "-" +
              clickedCalData.garag +
              "-" +
              this.calendarEeljIndex +
              "-0" +
              clickedCalData.time;
            console.log("df", calString);
            console.log(clickedCalData.lesson, "path");
            console.log(
              clickedCalData.lesson._calDatas,
              clickedCalData.lesson.ref.path,
              calString
            );
            clickedCalData.lesson.ref
              .update({
                _calDatas: fb.firestore.FieldValue.arrayRemove(calString),
              })
              .then(() => {
                this.selectedDay = null;
                this.selectedXeelj = null;
                this.selectLessonDialog = false;
              });
          }
        });
      }
      // calendarEeljIndex
      // this.selectedLesson.already = true;
      // this.selectLessonDialog = true;
      // console.log(
      //   this.selectedLesson.ref.path,
      //   calendarEeljIndex,
      //   this.selectedLesson
      // );
    },

    async _addNewCalData(day, xeelj) {
      if (!this.readable) {
        console.log(this.calendarEeljIndex, day, xeelj);
        this.selectedDay = day;
        this.selectedXeelj = xeelj;
        if (!this.onlyAllowedLesson) this.selectedLesson = null;
        else {
          this.selectedLesson = this.lessons.find(
            (lesson) => this.onlyAllowedLesson.ref.path == lesson.ref.path
          );
          console.log(this.selectedLesson);
        }
        this.selectLessonDialog = true;
      }
    },
    _addCorrespondingCalDatas(lesson, day, xeelj, calDatas) {
      // console.log(this.calendarEeljIndex);
      for (const calData of lesson._calDatasConverted) {
        if (
          calData.semester == this.selectedSemester + 1 &&
          calData.calendarVersion == 1 &&
          calData.garag == day.dayIndex &&
          calData.eelj == this.calendarEeljIndex &&
          calData.time == xeelj
        ) {
          calData.lesson = lesson;
          // console.log(lesson, "llll");
          var cc = this._getCellColor(lesson);
          calData.style =
            "background-color:" + cc.fgColor + "; color:" + cc.bgColor + ";";
          calData.name = lesson.courseInfo.SUBJECT_AREA_NAME.substring(0, 15);
          if (lesson.courseInfo.SUBJECT_AREA_NAME.length > 15)
            calData.name = calData.name + "...";
          if (lesson.esisLessonType.esisLessonTypeId > 1)
            calData.lessonTypeName =
              lesson.esisLessonType.name +
              (lesson.selectedGroupName
                ? " -- " + lesson.selectedGroupName
                : "");

          if (
            lesson.courseInfo &&
            lesson.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE"
          ) {
            if (lesson.courseInfo.COURSE_NAME.includes("БЗЦ"))
              calData.name = calData.name + " [БЗЦ]";
            else calData.name = calData.name + " [C]";
          }
          calData.teachers = this._getTeacherNames(calData);

          calDatas.push(calData);
        }
      }
    },
    _getLesson2(day, xeelj, index, calendarEeljIndex) {
      var ll = this._getCellLessons(day, xeelj, calendarEeljIndex);
      return ll[index];
    },

    _getCellLessons(day, xeelj) {
      var calDatas = [];
      for (const ll of this.lessons) {
        if (ll._calDatasConverted)
          this._addCorrespondingCalDatas(ll, day, xeelj, calDatas);
      }

      // if (this.selectedClassGroup && this.calendarData) {
      //   for (var ca of this.calendarData) {
      //     if (ca.selectedCalendarEeljIndex == calendarEeljIndex) {
      //       if (ca.dayIndex == day.dayIndex && ca.xeelj == xeelj) {
      //         var kk = ca;
      //         var cc = this._getCellColor(ca);
      //         kk.style =
      //           "background-color:" +
      //           cc.fgColor +
      //           "; color:" +
      //           cc.bgColor +
      //           ";";
      //         kk.name = kk.courseInfo.SUBJECT_AREA_NAME.substring(0, 15);

      //         if (
      //           kk.courseInfo &&
      //           kk.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE"
      //         ) {
      //           if (kk.courseInfo.COURSE_NAME.includes("БЗЦ"))
      //             kk.name = kk.name + " [БЗЦ]";
      //           else kk.name = kk.name + " [C]";
      //         }

      //         kk.teachers = this._getTeacherNames(kk);
      //         calDatas.push(kk);
      //       }
      //     }
      //   }
      // }
      return calDatas;
      // return "BB-" + day.dayIndex + "/" + xeelj.index;
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
};
</script>
